<script>
  export default {
    name: 'pagination',
    props: {
      next: {
        type: String,
        default: null
      },
      previous: {
        type: String,
        default: null
      },
      currentPage: {
        type: Number,
        default: 1
      },
      totalItems: {
        type: Number,
        default: 0
      }
    },
    data(){
      return {
        hasMore: false,
        hasLess: false,
        // pages: Math.ceil(this.totalItems / 20)
      }

    },
    computed: {
      pages(){
        const pagination = [];
        const totalPages = Math.ceil(this.totalItems / 20)

        if(totalPages <= 10){
          for (let i = 1; i <= totalPages; i++) {
            pagination.push(i);
          }
        } else {
          if (this.currentPage <= 5) {
            for (let i = 1; i <= 10; i++) {
              pagination.push(i);
            }
            pagination.push('...');
          } else if (this.currentPage >= totalPages - 4) {
            pagination.push('...');
            for (let i = this.totalItems - 9; i <= totalPages; i++) {
              pagination.push(i);
            }
          } else {
            pagination.push('...');
            for (let i = this.currentPage - 3; i <= this.currentPage + 3; i++) {
              pagination.push(i);
            }
            pagination.push('...');
            pagination.push(totalPages);
          }
        }

        return pagination
      }
    },
    methods: {
      navigate(page){
        if (page !== '...'){

          this.$emit('navigate', page)
        }
      },
      nextPage(){
        this.$emit('nextPage')
      },
      previousPage(){
        this.$emit('previousPage')
      }
    }
  }
</script>

<template>
  <div v-if="next" class="align-items-center mt-4 pt-2 justify-content-between d-flex ">
    <div class="flex-shrink-0">
      <div class="text-muted">
<!--        Showing <span class="fw-semibold">5</span> of-->
<!--        <span class="fw-semibold">{{ totalItems }}</span> Results-->
      </div>
    </div>
    <ul
        class="pagination pagination-separated pagination-sm mb-0 d-flex flex-wrap"
    >
      <li class="page-item">
        <a href="#" role="button" @click="previousPage" class="page-link">←</a>
      </li>
      <li :class="{'active': page === currentPage}" v-for="(page, index) in pages" :key="index" class="page-item">
        <a :class="{'disable-links': page === currentPage}" href="#" role="button" class="page-link" @click="navigate(page)">{{ page }}</a>
      </li>
      <li class="page-item">
        <a href="#" role="button" @click="nextPage" class="page-link">→</a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  a.disable-links {
    pointer-events: none;
  }
</style>